import React from "react"
import {Container, Row, Col, Jumbotron} from 'react-bootstrap'
import Header from "../components/header"
import Footer from "../components/footer"
import ControlledCarousel from "../components/carousel"
import SEO from "../components/seo"
import AdvertContact from "../components/advert"
import AllServices from "../components/AllServices"
const IndexPage = () => (
  <>
    <Header />
    <main>
      <SEO title="Home" />
      <Jumbotron className='jumbatron p-5'>
        <Container>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{justifyContent: 'center', textAlign: 'center', marginBottom: 10}}>
              <h1>Build it Fast. <br/> Build it First.</h1>
            </div>
            <div style={{justifyContent: 'center', display: 'flex'}}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <div className="ops-icon" id="imgOpsDataIcon"></div>
                <div style={{minWidth: 20}}></div>
                <div className="ops-icon" id="imgOpsCloudIcon"></div>
                <div style={{minWidth: 20}}></div>
                <div className="ops-icon" id="imgOpsAppIcon"></div>
              </div>
            </div>
          </div>
        </Container>
      </Jumbotron>
      <Container>
        <Row className="mb-3">
          <Col lg={true} md={12} className='m-3 p-3 services-short-green'>
            <h2 className='text-center mb-3'>Machine Learning</h2>
            <p className="text-center">
              Extract rich data insights with machine learning, natural language processing, and geospatial analysis.
            </p>
          </Col>
          <Col lg={true} md={12} className='m-3 p-3 services-short-green'>
            <h2 className='text-center mb-3'>Cloud Applications</h2>
            <p className="text-center">
              Build secure, resilient, cost efficient cloud applications with Amazon Web Services, Python and React.
            </p>
          </Col>
        </Row>
        <div className="client-carousel">
          <ControlledCarousel />
        </div>
        <AllServices />
        <AdvertContact /> 
      </Container>
    </ main>
    <Footer />
  </>
)

export default IndexPage
