import React from "react"
import {Row, Col} from 'react-bootstrap'
import { useStaticQuery, graphql  } from "gatsby"
import Img from "gatsby-image"

const AllServices = () => {
  const data = useStaticQuery(graphql`
    query {
      q1: file(relativePath: { eq: "pexels-cottonbro-5191387.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      q2: file(relativePath: { eq: "pexels-alphatradezone-5831511.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      q3: file(relativePath: { eq: "pexels-pixabay-38271.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      q4: file(relativePath: { eq: "pexels-vlada-karpovich-4050287.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      q5: file(relativePath: { eq: "pexels-lukas-577210.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      q6: file(relativePath: { eq: "pexels-elevate-1267362.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      q7: file(relativePath: { eq: "pexels-dan-nelson-3949100.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return(
  <>
    <div className="mt-2">
      <Row className="mt-5 mb-5"></Row>
      <Row className='services-short-green no-gutters mt-5 mb-5 shadow-sm'>
        <Col lg={true} md={6} className='p-4 bg-light'>
          <h4 className='text-center'>Project Design and Data Strategy</h4>
          <p>Through co-design, Opsmorph can asssist you with data strategy, problem definition, technical requirements gathering, and technical project management.</p>
        </Col>
        <Col lg={true} md={6} className="bg-light">
          <Img
            className="h-100 w-100"
            fluid={data.q1.childImageSharp.fluid}
            alt={`Project design and data strategy service`}
          />
        </Col>
      </Row>
      <Row className="mt-5 mb-5"></Row>
      <Row className='services-short-red no-gutters mt-5 mb-5 shadow-sm'>
        <Col lg={true} md={6} className="bg-light">
          <Img
            className="h-100 w-100"
            fluid={data.q2.childImageSharp.fluid}
            alt={`Machine Learning service`}
          />
        </Col>
        <Col lg={true} md={6} className='p-5 bg-light'>
          <h4 className='text-center'>Machine Learning</h4>
          <p>Opsmorph consultants have deep exeprtise in a wide range of big data, machine learning and deep learning techniques for algorithm development, as well as proficency in the deployment and productionisation of machine learning in the cloud.</p>
        </Col>
      </Row>
      <Row className="mt-5 mb-5"></Row>
      <Row className='services-short-green no-gutters mt-5 mb-5 shadow-sm'>
        <Col lg={true} md={6} className='p-5 bg-light'>
          <h4 className='text-center'>Geospatial Data Science</h4>
          <p>We offer specialist services in the sourcing, merging, and analysis of geospatial data for the development of all your geospatial data models.</p>
        </Col>
        <Col lg={true} md={6} className="bg-light">
          <Img
            className="h-100 w-100"
            fluid={data.q3.childImageSharp.fluid}
            alt={`Geospatial Data Science service`}
          />
        </Col>
      </Row>
      <Row className="mt-5 mb-5"></Row>
      <Row className='services-short-red no-gutters mt-5 mb-5 shadow-sm'>
        <Col lg={true} md={6} className="bg-light">
          <Img
            className="h-100 w-100"
            fluid={data.q4.childImageSharp.fluid}
            alt={`NLP service`}
          />
        </Col>
        <Col lg={true} md={6} className='p-5 bg-light'>
          <h4 className='text-center'>Deep Learning with Text</h4>
          <p>Our competency includes extracting insights from text using natural language processing (NLP), structuring unstructured data, and automating data pipelines to scrape and stream data from the internet.</p>
        </Col>
      </Row>
      <Row className="mt-5 mb-5"></Row>
      <Row className='services-short-green no-gutters mt-5 mb-5 shadow-sm'>
        <Col lg={true} md={6} className='p-5 bg-light'>
          <h4 className='text-center'>Web Applications</h4>
          <p>We can help develop cloud hosted user interfaces and customizable data visualization dashboards with React and Amazon Web Services.</p>
        </Col>
        <Col lg={true} md={6} className="bg-light">
          <Img
            className="h-100 w-100"
            fluid={data.q5.childImageSharp.fluid}
            alt={`Web Applications and Dashboards Service`}
          />
        </Col>
      </Row>
      <Row className="mt-5 mb-5"></Row>
      <Row className='services-short-red no-gutters mt-5 mb-5 shadow-sm'>
        <Col lg={true} md={6} className="bg-light">
          <Img
            className="h-100 w-100"
            fluid={data.q6.childImageSharp.fluid}
            alt={`Cloud Data Storage service`}
          />
        </Col>
        <Col lg={true} md={6} className='p-5 bg-light'>
          <h4 className='text-center'>Cloud Data Lakes</h4>
          <p>Opsmorph's certified cloud solution architects can help you build secure, scalable, cost-efficient, fault tolerant data lakes in the cloud to store and catalogue data of various formats, from disparate sources.</p>
        </Col>
      </Row>
      <Row className="mt-5 mb-5"></Row>
      <Row className='services-short-green no-gutters mt-5 mb-5 shadow-sm'>
        <Col lg={true} md={6} className='p-5 bg-light'>
          <h4 className='text-center'>Cloud Security</h4>
          <p>At Opsmorph, we prioritise secure development in the cloud and can assist in the implementation of secure authentication, authorization, logging, and data encryption both at rest and in transit.</p>
        </Col>
        <Col lg={true} md={6}>
          <Img
            className="h-100 w-100"
            fluid={data.q7.childImageSharp.fluid}
            alt={`Cloud Security service`}
          />
        </Col>
      </Row>
      <Row className="mt-5 mb-5"></Row>
    </div>
  </>
)
}
export default AllServices