import React, { useState } from "react"
import { Link, useStaticQuery, graphql  } from "gatsby"
import Img from "gatsby-image"
import { Carousel } from 'react-bootstrap'

const carouselItems = [
  {
    title: 'Client Story - AmbiTek',
    subtitle: 'Improving office air quality with IoT',
    link: '/Blog/client-story-ambitek'
  },
  {
    title: 'Geospatial Visualisation ',
    subtitle: 'With React, Mapbox-GL, and Amazon Location Service',
    link: '/Blog/geospatial-visualisation-blog'
  },
  {
    title: 'Blog - Web Development',
    subtitle: 'With Gatsby, React, Boostrap and AWS Amplify',
    link: '/Blog/client-story-ambitek'
  },
  {
    title: 'Analysing COVID-19 Vaccination Rates by Demographics',
    subtitle: 'With Python, Pandas, Plotly and ipyleaflet',
    link: '/Blog/vaccine-rate-demographics'
  },
  {
    title: 'Analysing Furlough and Unemployment During COVID-19',
    subtitle: 'With Python, Pandas and Plotly',
    link: '/Blog/furlough-and-unemployment'
  },
  {
    title: 'Cognito User Group Spoofing on Amazon Web Services',
    subtitle: 'Opsmorph discover web app cyber security vulnerability',
    link: '/Blog/usergroupspoofing'
  },
  {
    title: 'Cloud Provider Popularity on Twitter and Stack Overflow',
    subtitle: 'With Python, Requests, Pandas and Plotly',
    link: '/Blog/cloud-providers-on-twitter-and-stackoverflow'
  }
]


const ControlledCarousel = () => {

    const [index, setIndex] = useState(0)
    const handleSelect = (selectedIndex, e) => setIndex(selectedIndex)
    const data = useStaticQuery(graphql`
      query {
        q1: file(relativePath: { eq: "pexels-jimmy-chan-1055297.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
    
        q2: file(relativePath: { eq: "GeospatialVizImages/geospatialBlog4.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
    
        q3: file(relativePath: { eq: "pexels-luis-quintero-2148222.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }

        q4: file(relativePath: { eq: "FullVaccBubble1.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }

        q5: file(relativePath: { eq: "FurloughImages/FurloughAndUnemployment.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }

        q6: file(relativePath: { eq: "CognitoVulnerabilityImages/UserGroupSpoofing.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }

        q7: file(relativePath: { eq: "CloudProvidersImages/CloudTechStock.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }

      }
    `)
  
    return (
      <Carousel activeIndex={index} onSelect={handleSelect} id="main-carousel">
        {carouselItems.map( (_item, _index) => 
          <Carousel.Item style={{ height: `350px`}} key={_index}>
            <Img
                className="h-100 w-100"
                fluid={data[`q${_index + 1}`].childImageSharp.fluid}
                alt={`Carousel slide ${_index}`}
            />
            <Carousel.Caption className='carousel-caption' style={{backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
              <h3>{_item.title}</h3>
              <p>{_item.subtitle}</p>
              <Link to={_item.link} className='btn btn-primary' activeClassName='active'>READ</Link>
            </Carousel.Caption>
          </Carousel.Item>
        )}
      </Carousel>
    )
}

export default ControlledCarousel
